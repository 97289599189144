import CommonHeader from "../components/CommonHeader";
import React from "react";
import CommonFooter from "../components/CommonFooter";
import {Container, CssBaseline, Typography} from "@material-ui/core";

const About = (props: any) => {
  return (
    <div>
      <CommonHeader subTitle="このページについて"/>

      <Container component="main" maxWidth="md">
        <CssBaseline/>

        <Typography>
          STU従業員向けの業務上必要な申請などを行うページです
        </Typography>

      </Container>

      <CommonFooter/>
    </div>
  );
};

export default About;
