import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import {
  AppBar,
  Drawer,
  Divider,
  Link,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // Toolbar,
  Typography,
  Menu,
  MenuItem
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  AccountCircle,
  Inbox as InboxIcon,
  Home as HomeIcon,
  WbSunny as WbSunnyIcon,
  Brightness3 as Brightness3Icon,
  Help as HelpIcon,
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  LiveHelp as LiveHelpIcon
} from '@material-ui/icons';
import { useAuthContext } from '../contexts/AuthContext';
import { useCustomThemeContext } from '../contexts/ThemeContext';
import { useSystemMessageContext } from '../contexts/SystemMessageContext';

const MessageArea = (props: any) => {
  const { message, severity, setMessage } = useSystemMessageContext();

  const onClose = (event: any) => {
    setMessage && setMessage('', 'info');
  };

  if (message) {
    return (
      <>
        <Alert style={{ borderRadius: 0 }} severity={severity} onClose={onClose}>
          {message}
        </Alert>
      </>
    );
  }
  return <></>;
};

const ThemeButton = (props: any) => {
  const { currentTheme, toggleTheme } = useCustomThemeContext();

  const onClickToggleTheme = (event: any) => {
    event.preventDefault();

    if (toggleTheme) {
      toggleTheme();
    }
  };

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      menuButton: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(2)
      }
    });
  });

  const classes = useStyles();

  return (
    <IconButton className={classes.menuButton} color="inherit" onClick={onClickToggleTheme}>
      {currentTheme === 'dark' && <WbSunnyIcon />}
      {currentTheme === '' && <Brightness3Icon />}
    </IconButton>
  );
};

const HelpButton = (props: any) => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProfileMenu = (event: any) => {
    setAnchorEl(null);
  };

  const onClickToolTip = (event: any) => {
    console.log('click tool tip');
  };
  const onClickAbout = (event: any) => {
    history.push('/about/');
  };

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      menuButton: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(2)
      },

      menuItem: {
        padding: 0,
        margin: 0
      }
    });
  });

  const classes = useStyles();

  return (
    <>
      <IconButton className={classes.menuButton} color="inherit" onClick={onClickButton}>
        <HelpIcon />
      </IconButton>

      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseProfileMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem className={classes.menuItem} onClick={onClickToolTip} disabled>
          <ListItem button key="home">
            <ListItemIcon>
              <LiveHelpIcon />
            </ListItemIcon>
            <ListItemText primary="ツールチップを有効にする" />
          </ListItem>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={onClickAbout}>
          <ListItem button key="home">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="このページについて" />
          </ListItem>
        </MenuItem>
      </Menu>
    </>
  );
};

const AccountButton = (props: any) => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProfileMenu = (event: any) => {
    setAnchorEl(null);
  };

  const onClickProfile = (event: any) => {
    history.push('/profile/');
  };
  const onClickLogout = (event: any) => {
    history.push('/logout/');
  };

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      menuButton: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(2)
      },

      menuItem: {
        padding: 0,
        margin: 0
      }
    });
  });

  const classes = useStyles();

  return (
    <>
      <IconButton className={classes.menuButton} color="inherit" onClick={onClickButton}>
        <AccountCircle />
      </IconButton>

      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseProfileMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem className={classes.menuItem} onClick={onClickProfile}>
          <ListItem button key="home">
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="ユーザープロファイル" />
          </ListItem>
        </MenuItem>
        <Divider />
        <MenuItem className={classes.menuItem} onClick={onClickLogout}>
          <ListItem button key="home">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItem>
        </MenuItem>
      </Menu>
    </>
  );
};

const CommonHeader = (props: any) => {
  const { currentUser } = useAuthContext();
  const [drawerState, setDrawerState] = useState(false);

  let subTitle = '';
  if (props.subTitle) {
    subTitle = '　/　' + props.subTitle;
  }

  const useStyles = makeStyles((theme: Theme) => {
    // TODO: messageが表示されていた場合は高さを変更する
    // console.log(theme.mixins.toolbar);

    return createStyles({
      appBar: {
        display: 'flex',
        flexDirection: 'column'
      },
      appBarTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      offset: theme.mixins.toolbar,
      menuButton: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(2)
      },
      title: {
        display: 'none',

        [theme.breakpoints.up('sm')]: {
          display: 'block',

          color: theme.palette.primary.contrastText
        }
      },
      list: {
        width: '250px'
      },
      grow: {
        flexGrow: 1
      }
    });
  });

  const classes = useStyles();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    console.log('toggleDrawer');
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerState(!drawerState);
  };

  const DrawerMenu = (props: any) => {
    if (currentUser) {
      return (
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <Link href="/" color="textSecondary" underline="none">
              <ListItem button key="home">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="ホーム" />
              </ListItem>
            </Link>

            <Link href="/petition/" color="textSecondary" underline="none">
              <ListItem button key="petition">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="各種申請" />
              </ListItem>
            </Link>

            <Link href="/equipment/" color="textSecondary" underline="none">
              <ListItem button key="equipment">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="機材・資料" />
              </ListItem>
            </Link>

            <Link href="/milestone/" color="textSecondary" underline="none">
              <ListItem button key="milestone">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="マイルストーン" />
              </ListItem>
            </Link>

            <Link href="/map/" color="textSecondary" underline="none">
              <ListItem button key="floormap">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="マップ" />
              </ListItem>
            </Link>

            <Link href="/about/" color="textSecondary" underline="none">
              <ListItem button key="about">
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="このページについて" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          {currentUser && (
            <List>
              <Link href="/profile/" color="textSecondary" underline="none">
                <ListItem button key="profile">
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary="ユーザープロファイル" />
                </ListItem>
              </Link>
              <Link href="/logout/" color="textSecondary" underline="none">
                <ListItem button key="logout">
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="ログアウト" />
                </ListItem>
              </Link>
            </List>
          )}
        </div>
      );
    } else {
      return (
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <Link href="/" color="textSecondary" underline="none">
              <ListItem button key="home">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="ホーム" />
              </ListItem>
            </Link>
          </List>
        </div>
      );
    }
  };

  return (
    <>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <div className={classes.appBarTitle}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Link href={'/'} underline="none">
            <Typography className={classes.title} variant="h6" noWrap>
              ダッシュボード
            </Typography>
          </Link>
          <Typography className={classes.title} variant="h6" noWrap>
            {subTitle}
          </Typography>

          <div className={classes.grow} />

          <div>
            <ThemeButton />
            <HelpButton />
            {currentUser && <AccountButton />}
          </div>
        </div>

        <MessageArea />
      </AppBar>
      <div className={classes.offset} />

      <Drawer anchor="left" open={drawerState} onClose={toggleDrawer(false)}>
        <DrawerMenu />
      </Drawer>
    </>
  );
};

export default CommonHeader;
