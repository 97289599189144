import React, {createContext, useContext, useState} from 'react';
import {createTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/core/styles';

interface IThemeContext {
  currentTheme: string | null | undefined,
  toggleTheme: (() => void) | null,
}

const CustomThemeContext = createContext<IThemeContext>({
  currentTheme: 'normal',
  toggleTheme: null
});

const useCustomThemeContext = () => {
  return useContext(CustomThemeContext);
};

const CustomThemeProvider = (props: any) => {
  // Get current theme from localStorage
  const currentTheme = localStorage.getItem('appTheme') || '';

  // State to hold selected theme
  const [themeName, setThemeName] = useState(currentTheme);

  const getTheme = (name: string) => {
    let options = {};
    if (name === 'dark') {
      options = {
        palette: {
          type: 'dark',
          primary:{
            main: '#333b63'
          },
          secondary:{
            main: '#d2497a'
          }
        },
      }
    }
    return createTheme(options);
  }

  const toggleThemeName = () => {
    // let newPaletteType = theme.palette.type === "light" ? "dark" : "light";
    const newThemeName = themeName === "" ? "dark" : "";

    localStorage.setItem('appTheme', newThemeName);
    setThemeName(newThemeName);
  };

  const contextValue = {
    currentTheme: themeName,
    toggleTheme: toggleThemeName
  };

  // Retrieve theme object by theme name
  const theme = getTheme(themeName);

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export {CustomThemeProvider, useCustomThemeContext};
