import { createContext, useState, useContext, useEffect } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

interface IAuthContext {
  currentUser: firebase.User | null | undefined;
  role: string | null | undefined;
}

const AuthContext = createContext<IAuthContext>({ currentUser: undefined, role: undefined });

const useAuthContext = () => {
  return useContext(AuthContext);
};

const AuthProvider = (props: any) => {
  const [currentUser, setCurrentUser] = useState<firebase.User>();
  const [role, setRole] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const value = {
    currentUser,
    role
  };

  console.log(loading);

  useEffect(() => {

    const auth = firebase.auth();
    // Firebase Authのメソッド。ログイン状態が変化すると呼び出される
    auth.onAuthStateChanged(async (user: any) => {
      setCurrentUser(user);

      if (!user) {
        setRole('');
        return;
      }

      setLoading(true);

      const _firestore = firebase.firestore();
      const _users = _firestore.collection('users').where('email', '==', user.email);

      // ユーザー情報を取得して権限を設定
      // firebase.QuerySnapshot<firebase.DocumentData>
      try {
        // user
        const querySnapshot = await _users.get();
        if (querySnapshot.size === 0) {
          return null;
        }
        if (querySnapshot.size > 1) {
          console.warn(`Find ${querySnapshot.size} organizations.`);
        }
        const _data = querySnapshot.docs[0].data();
        setRole(_data.role);
      } catch (error) {
        console.error('Error getting user role: ', error);
      } finally {
        setLoading(false);
      }
    });
  }, []);

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export { useAuthContext, AuthProvider };
