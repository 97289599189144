import React from 'react';
// import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Link, Box } from '@material-ui/core';
import { ReactComponent as CorporateLogo } from '../images/stu_logo_c_1.svg';

const CommonFooter = (props: any) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      },

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',

      marginTop: theme.spacing(3),

      fontSize: '10px'
    },
    logo: {
      '& > *': {
        margin: theme.spacing(1)
      },

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      color: theme.palette.grey[800],
      backgroundColor: theme.palette.grey[50],

      borderRadius: theme.spacing(2),

      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    },
    grow: {
      flexGrow: 1
    }
  }));
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root} borderTop={1} borderColor="grey.400">
        <Link
          href="https://www.stu-inc.com"
          target="_blank"
          rel="noopener noreferrer"
          color="textSecondary"
          underline="none"
        >
          <div className={classes.logo}>
            <CorporateLogo style={{ height: '20px' }} />
            &copy; SMILE TECHNOLOGY UNITED, INC.
          </div>
        </Link>
        <div className={classes.grow} />
        <div>
          <Link href="mailto:admin@stu-inc.com" color="textSecondary" underline="none">
            管理者へ連絡
          </Link>
        </div>
      </Box>
    </>
  );
};

export default CommonFooter;
