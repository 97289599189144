import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Fade, Container, Button, ButtonBase, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import { TransitionGroup } from 'react-transition-group';

import CommonHeader from '../components/CommonHeader';
import CommonFooter from '../components/CommonFooter';

import { Login } from '../components/LoginWithGoogle';
import { ReactComponent as PetitionIcon } from '../images/petition.svg';
import { ReactComponent as EquipmentIcon } from '../images/equipment.svg';
import { ReactComponent as MilestoneIcon } from '../images/milestone.svg';
import { ReactComponent as MapIcon } from '../images/map.svg';
import { useAuthContext } from '../contexts/AuthContext';

const AdminButton = (props: any) => {
  const [appear, setAppear] = useState(false);

  let history = useHistory();
  const { currentUser, role } = useAuthContext();

  useEffect(() => {
    console.log(currentUser);

    if (role === 'admin' || role === 'owner') {
      setAppear(true);
    }
  }, [role]);

  if (role === 'admin' || role === 'owner') {
    return (
      <Fade in={appear} timeout={2000}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          className={props.classes}
          onClick={() => history.push('/admin/')}
        >
          管理者画面へ
        </Button>
      </Fade>
    );
  }
  return <div />;
};

const Home = (props: any) => {
  let history = useHistory();
  const { currentUser, role } = useAuthContext();

  console.log(role);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      },

      minHeight: '500px',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    welcome: {
      fontFamily: "'M PLUS Rounded 1c', sans-serif",
      marginTop: '80px',
      marginBottom: '80px',
      fontSize: '24px',
      fontWeight: 'bold',
      transform: 'rotate(0.05deg)',

      // BGをグラデーションにし、文字でくりぬく
      background:
        'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);' +
        'background-size: 400% 400%;',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',

      // グラデーションをアニメーションさせる
      animation: '$gradient 20s ease infinite'
    },
    '@keyframes gradient': {
      '0%': {
        backgroundPosition: '0% 50%'
      },
      '50%': {
        backgroundPosition: '100% 50%'
      },
      '100%': {
        backgroundPosition: '0% 50%'
      }
    },
    login: {
      '& > *': {
        margin: theme.spacing(1)
      },

      minHeight: '500px',

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      //flexWrap: "wrap",
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20)
    },
    buttons: {
      '& > *': {
        margin: theme.spacing(1)
      },

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly'
    },
    button: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',

      width: '130px',
      height: '130px',
      padding: theme.spacing(1),

      borderRadius: '3px',

      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,

      transition: 'all 0.15s ease',

      '&:hover, &$focusVisible': {
        backgroundColor: theme.palette.primary.dark,
        transform: 'scale(1.05)'
      }
    },
    focusVisible: {},
    admin: {
      margin: theme.spacing(10)
    }
  }));
  const classes = useStyles();

  const HomeSwitcher = (props: any) => {
    if (currentUser === undefined) {
      return <div className={classes.root} />;
    } else if (currentUser) {
      return (
        <div className={classes.root}>
          <div className={classes.welcome}>
            ようこそ {currentUser.displayName} さん ({currentUser.email})
          </div>

          <div className={classes.buttons}>
            <ButtonBase className={classes.button} onClick={() => history.push('/petition/')}>
              <PetitionIcon style={{ width: '70px' }} />
              <div>各種申請</div>
            </ButtonBase>

            <ButtonBase className={classes.button} onClick={() => history.push('/equipment/')}>
              <EquipmentIcon style={{ width: '70px' }} />
              <div>機材・資料</div>
            </ButtonBase>

            <ButtonBase className={classes.button} onClick={() => history.push('/milestone/')}>
              <MilestoneIcon style={{ width: '70px' }} />
              <div>マイルストーン</div>
            </ButtonBase>

            <ButtonBase className={classes.button} onClick={() => history.push('/map/')}>
              <MapIcon style={{ width: '70px' }} />
              <div>マップ</div>
            </ButtonBase>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.login}>
          <Login />
        </div>
      );
    }
  };
  return (
    <div>
      <CommonHeader />
      <Container component="main" maxWidth="md" className={classes.root}>
        <CssBaseline />

        <HomeSwitcher />

        <AdminButton classNames={classes.admin} />
      </Container>
      <CommonFooter />
    </div>
  );
};

export default Home;
