import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { ReactComponent as GoogleSvg } from '../images/btn_google_light_normal_ios.svg';
import { makeStyles } from '@material-ui/core/styles';

import firebase from 'firebase/app';

const config = {
  web: {
    client_id: '547295711627-ig5chti3kb30mudsm551dfbc6kkkt67e.apps.googleusercontent.com',
    project_id: 'employeedashboard-c2d2a',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_secret: 'cDEEwTt6sueF-R8mb2OXw6RF',
    redirect_uris: ['https://employeedashboard-c2d2a.firebaseapp.com/__/auth/handler'],
    javascript_origins: [
      'http://localhost',
      'http://localhost:5000',
      'https://employeedashboard-c2d2a.firebaseapp.com',
      'http://localhost:3000',
      'https://dashboard.stu.yokohama'
    ]
  }
};

const CLIENT_ID = config.web.client_id;
const API_KEY = 'AIzaSyBHYwTQHjr61vqt6ATGsfOqcSrl5puJFJo';

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES =
  'https://www.googleapis.com/auth/calendar.readonly ' +
  'https://www.googleapis.com/auth/drive ' +
  'https://www.googleapis.com/auth/spreadsheets ';

const init_client = async () => {
  const loadApiResult = await new Promise((res, rej) => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = res;
    script.onerror = rej;
    document.head.appendChild(script);
  });

  console.log('api.js is loaded.');
  console.log(loadApiResult);

  console.log('load client, auth');
  const loadAuth2Result = await new Promise((res, rej) => {
    window.gapi.load('client:auth2', { callback: res, onerror: rej });
  });
  console.log(loadAuth2Result);

  console.log(window.gapi.auth2.getAuthInstance());
  console.log('init client');
  const loadCalendarResult = await window.gapi.client.init({
    apiKey: API_KEY,
    clientId: CLIENT_ID,
    discoveryDocs: DISCOVERY_DOCS,
    scope: SCOPES
  });
  console.log(loadCalendarResult);

  return loadCalendarResult;
};

const Login = (props: any) => {
  let history = useHistory();

  const _isUserEqual = (googleUser: gapi.auth2.GoogleUser, firebaseUser: firebase.User) => {
    if (firebaseUser) {
      const providerData = firebaseUser.providerData;
      for (let i = 0; i < providerData.length; i++) {
        const data = providerData[i];
        if (data !== null) {
          if (
            data.providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
            data.uid === googleUser.getBasicProfile().getId()
          ) {
            // We don't need to reauth the Firebase connection.
            return true;
          }
        }
      }
    }
    return false;
  };

  const onClick = async (event: any) => {
    event.preventDefault();

    try {
      await init_client();

      const googleUser = await window.gapi.auth2.getAuthInstance().signIn();
      console.log(googleUser);

      /*
      const provider = new firebase.auth.GoogleAuthProvider();
      SCOPES.split(' ').forEach((item) => {
        provider.addScope(item);
      });

      const userCredential = await firebase.auth().signInWithPopup(provider);
      const firebaseUser = userCredential.user;
      if (firebaseUser === null) {
        throw new Error('Firebase認証中にエラーが発生しました');
      }
      if (_isUserEqual(googleUser, firebaseUser)) {
        // FirebaseとGoogleで同じユーザーでログインしていたら問題なし
        history.push('/');
        return;
      }
       */

      // GoogleのCredentialでFirebaseにログインしなおす
      const credential = await firebase.auth.GoogleAuthProvider.credential(
        googleUser.getAuthResponse().id_token
      );

      // Sign in with credential from the Google user.
      const userCredential_2 = await firebase.auth().signInWithCredential(credential);

      console.log('Sign in with credential');
      console.log(userCredential_2);
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > span>span': {
        width: '46px',
        height: '46px'
      },
      '& > span>span>svg': {
        width: '46px',
        height: '46px'
      },

      '&:hover': {
        backgroundColor: 'rgb(255, 255, 255)'
      },
      color: 'rgb(117, 117, 117)',
      backgroundColor: 'rgb(255, 255, 255)',

      margin: 0,
      padding: '0px',
      paddingLeft: '12px',
      paddingRight: '12px',

      fontFamily: "'Roboto', sans-serif",
      textTransform: 'none',
      whiteSpace: 'nowrap'
    }
  }));
  const classes = useStyles();

  return (
    <>
      <Button
        startIcon={<GoogleSvg />}
        variant="contained"
        size="large"
        color="primary"
        className={classes.root}
        onClick={onClick}
      >
        Sign in with Google
      </Button>
    </>
  );
};

export { Login, init_client };
