import React, { createContext, useContext, useState } from 'react';

type SeverityType = 'error' | 'info' | 'success' | 'warning';

interface ISystemMessageContext {
  message: string | null | undefined;
  severity: SeverityType;
  setMessage: ((message: string, severity: SeverityType) => void) | null;
}

const CustomContext = createContext<ISystemMessageContext>({
  message: '',
  severity: 'info',
  setMessage: null
});

const useSystemMessageContext = () => {
  return useContext(CustomContext);
};

const SystemMessageProvider = (props: any) => {
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<SeverityType>('info');

  const _setMessage = (message: string, severity: SeverityType) => {
    setMessage(message);
    setSeverity(severity);
  };

  const contextValue = {
    message: message,
    severity: severity,
    setMessage: _setMessage
  };

  return <CustomContext.Provider value={contextValue}>{props.children}</CustomContext.Provider>;
};

export { SystemMessageProvider, useSystemMessageContext };
