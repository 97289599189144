import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CustomThemeProvider } from './contexts/ThemeContext';
import { SystemMessageProvider } from './contexts/SystemMessageContext';

import Home from './routes/Home';
import About from './routes/About';
import Logout from './routes/Logout';
import NotFound from './routes/NotFound';

const Petition = lazy(() => import('./routes/Petition'));
const FloorMap = lazy(() => import('./routes/FloorMap'));
const Milestone = lazy(() => import('./routes/Milestone'));
const Equipment = lazy(() => import('./routes/Equipment'));
const Profile = lazy(() => import('./routes/Profile'));
const Admin = lazy(() => import('./routes/Admin'));

function App() {
  return (
    <AuthProvider>
      <CustomThemeProvider>
        <SystemMessageProvider>
          <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/logout" component={Logout} />

                <Route path="/petition" component={Petition} />
                <Route path="/map" component={FloorMap} />
                <Route path="/milestone" component={Milestone} />
                <Route path="/equipment" component={Equipment} />

                <Route path="/profile" component={Profile} />

                <Route path="/about" component={About} />

                <Route path="/admin" component={Admin} />
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>
          </BrowserRouter>
        </SystemMessageProvider>
      </CustomThemeProvider>
    </AuthProvider>
  );
}

export default App;
