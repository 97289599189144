import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import firebase from 'firebase/app';

const Login = (props: any) => {
  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('ログアウトしました');
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return (
    <>
      <Redirect to="/" />
    </>
  );
};

export default Login;
