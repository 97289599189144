import React from "react";
import {useHistory} from "react-router-dom";
import {makeStyles, Theme, createStyles} from "@material-ui/core/styles";
import {
  Button,
  Container,
  Card,
  Typography, CssBaseline,
} from "@material-ui/core";
import {
  Error as ErrorIcon,
} from "@material-ui/icons";

const NotFound = (props: any) => {
    let history = useHistory();

    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        grow: {
          flexGrow: 1,
        },
        root: {
          "& > *": {
            margin: theme.spacing(2),
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '30px',
          padding: '10px',
        },
        icon: {
          color: '#f12513',
          fontSize: '50px'
        },
      }));

    const classes = useStyles();

    return (
      <>
        <Container component="main" maxWidth="sm">
          <CssBaseline/>
          <Card className={classes.root}>
            <ErrorIcon className={classes.icon}/>
            <Typography variant="h4" noWrap>
              404 Not Found
            </Typography>
            <Button variant="contained" disableElevation onClick={() => history.push("/")}>Back to
              Home</Button>
          </Card>
        </Container>

      </>
    );
  }
;

export default NotFound;
